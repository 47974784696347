.contact-section {
  padding: 3em 0;
}

.wrapper {
  width: 100%;
  -webkit-box-shadow: 0px 21px 41px -13px rgba(0, 0, 0, 0.18);
  -moz-box-shadow: 0px 21px 41px -13px rgba(0, 0, 0, 0.18);
  box-shadow: 0px 21px 41px -13px rgba(0, 0, 0, 0.18);
}

.contact-wrap {
  background: #B2282F;
  min-height: 100%;
  height: 100%;
}

.contact-wrap > h3 {
  color: #fff;
  position: relative;
}

.contact-wrap > h3:after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 83px;
  height: 3px;
  background: #fff;
  content: "";
}

.form-control {
  height: 32px;
  background: #fff;
  font-size: 16px;
  border-radius: 0px;
  box-shadow: none;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

textarea.form-control {
  height: inherit;
  margin-top: 5px;
}

input[type="text"]:focus, 
input[type="email"]:focus, 
textarea[type="text"]:focus {
  background-color : #fff;
  color: #B2282F;
  padding-left: 10px;
}

.contactForm > .form-control {
    border: none;
    border-bottom: 2px solid rgba(255, 255, 255, 0.2);
    padding: 0;
}

.btn {
  padding: 12px 16px;
  cursor: pointer;
  border-width: 1px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 400;
  -webkit-box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.12);
  position: relative;
  margin-top: 20px;
}

.btn-primary {
  background: #B2282F;
  border-color: #fff;
  color: #fff;
}

.btn-primary:hover {
  background-color: #fff;
  color: #B2282F;
  border-color: #B2282F;
}

.hidden {
  visibility: hidden;
  height: 0;
}

.info-wrap {
  font-size: 16px;
  min-height: 100%;
  height: 100%;
}

.info-wrap > h3 {
  color: #000;
  position: relative;
}

.info-wrap > h3:after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 70px;
  height: 3px;
  background: #B2282F;
  content: "";
}

h1,
h2,
h3,
h4,
h5,
.h1,
.h2,
.h3,
.h4,
.h5 {
  line-height: 1.5;
  font-weight: 400;
  font-family: "Poppins", Arial, sans-serif;
}

.icon-size {
  font-size: 18px;
}

.section-header {
  color: #000;
  position: relative;
}